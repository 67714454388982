import hero from '~/routes/_index/hero.jpg';
import HeroStats from '~/routes/_index/HeroStats';
import {Container, Flex, Stack} from '@styled-system/jsx';
import {css} from '@styled-system/css';
import {Button} from '~/components/ui/Button';
import {Link} from '@remix-run/react';
import BackgroundImage from '~/components/BackgroundImage';

const Hero = () => {
  return <BackgroundImage
    minH="50vh"
    src={hero}
  >
    <Container p={8} color="white" width="full">
      <Stack direction={{base: 'column', md: 'row'}} width="full" justify="space-between">
        <Flex maxW="40rem" alignItems="center">
          <Stack>
            <h1 className={css({
              fontSize: '6xl',
              textAlign: {
                mdDown: 'center',
              },
              fontFamily: 'heading',
              textTransform: 'uppercase',
              fontWeight: 700,
              lineHeight: 1,
            })}>
              Together,<br/>We Support Creators
            </h1>
            <p>
              Join our community to back your favorite creators. Your support helps them succeed and gives you a
              chance to win unique prizes.
            </p>
            <div>
              <Button
                asChild
                w={{mdDown: 'full'}}
              >
                <Link to="/promotions">
                  Explore our promotions
                </Link>
              </Button>
            </div>
          </Stack>
        </Flex>
        <HeroStats/>
      </Stack>
    </Container>
  </BackgroundImage>
}

export default Hero;
