import HeroStat from '~/routes/_index/HeroStat';
import {Divider, Stack} from '@styled-system/jsx';

const HeroStats = () => {
  return <Stack>
    <HeroStat number="$1,333,585" label="Paid to Creators"/>
    <Divider/>
    <HeroStat number="50.379M" label="Views"/>
    <Divider/>
    <HeroStat number="640" label="Videos Supported"/>
    <Divider/>
    <HeroStat number="56,354" label="Products Sold"/>
    <Divider/>
    <HeroStat number="44" label="Creators & Partners"/>
  </Stack>
}

export default HeroStats;
