import {Container, Flex, Stack, VStack} from '@styled-system/jsx';
import {h1} from '@styled-system/recipes';
import {css} from '@styled-system/css';
import DiamondIcon from '../../icons/diamond.svg?react';
import AwardIcon from '../../icons/award.svg?react';
import ThumbsUpIcon from '../../icons/thumbs-up.svg?react';
import PeopleIcon from '../../icons/people.svg?react';
import {ReactNode} from 'react';

const IconCircle = ({children}: {children: ReactNode}) => {
  return <Flex
    bga="red.900/5"
    width="48px"
    height="48px"
    justifyContent="center"
    alignItems="center"
    borderRadius="full"
    color="red.900"
  >
    {children}
  </Flex>
}

const BenefitCard = ({icon, title}: {icon: ReactNode, title: string}) => {
  return <VStack gap={{base: 4, lg: 8}} flex={1} textAlign="center" p={4}>
    <IconCircle>
      {icon}
    </IconCircle>
    <p className={css({
      fontFamily: 'heading',
      textTransform: 'uppercase',
      fontSize: 'xl',
      fontWeight: '500',
    })}>
      {title}
    </p>
  </VStack>;
}

const BuildingDreamsTogether = () => {
  return <Container width="full">
    <VStack gap={8}>
      <h1 className={h1()}>
        Building Dreams Together
      </h1>
      <p className={css({maxW: '800px', textAlign: 'center'})}>
        We are a proud veteran-owned small business, printing and shipping our collectibles from the USA. We support
        fellow small businesses, entrepreneurs, and creators in pursuing the American Dream. Head over to our promotions
        page and enter to win something awesome!
      </p>
      <Stack
        direction={{base: "row", mdDown: 'column'}}
        gap={0}
        p={4}
        width="full"
        justifyContent="space-between"
        className={css({
          lg: {
            '& > div + div': {
              borderLeftWidth: '1px',
              borderColor: 'gray.200',
              borderStyle: 'solid',
            }
          },
          mdDown: {
            '& > div + div': {
              borderTopWidth: '1px',
              borderColor: 'gray.200',
              borderStyle: 'solid',
            }
          }
        })}
      >
        <BenefitCard icon={<DiamondIcon/>} title="Exciting Promotions" />
        <BenefitCard icon={<AwardIcon/>} title="Limited Edition Merchandise" />
        <BenefitCard icon={<ThumbsUpIcon/>} title="Support the Creator Economy" />
        <BenefitCard icon={<PeopleIcon/>} title="Join a Community of Raving Fans" />
      </Stack>
    </VStack>
  </Container>;
}

export default BuildingDreamsTogether;
