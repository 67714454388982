import {Container, Grid, HStack, VStack} from '@styled-system/jsx';
import {h1} from '@styled-system/recipes'
import ContentCard from '~/components/ContentCard';

type MerchandiseForSaleProps = {
  products: {
    productId: string
    imageUrl: string
    title: string
  }[]
}

const MerchandiseForSale = ({products}: MerchandiseForSaleProps) => {
  if (!products.length) {
    return null;
  }

  return <Container width="full">
    <VStack gap={4}>
      <HStack width="full">
        <h1 className={h1()}>
          Merchandise For Sale
        </h1>
      </HStack>
      <Grid columns={{base: 1, md:3}} width="full">
        {products.map(product => (
          <ContentCard
            key={product.productId}
            link={`/merch/${product.productId}`}
            imageSrc={product.imageUrl}
            title={product.title}
          />
        ))}
      </Grid>
      {/*<Button asChild>*/}
      {/*  <Link to="/promotions">*/}
      {/*    See all Merch*/}
      {/*  </Link>*/}
      {/*</Button>*/}
    </VStack>
  </Container>
}

export default MerchandiseForSale;
