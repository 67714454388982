import {Container, Grid, HStack, VStack} from '@styled-system/jsx';
import {h1} from '@styled-system/recipes'
import PromotionCard, {PromotionCardProps} from '~/components/PromotionCard';
import {Button} from '~/components/ui/Button';
import {Link} from '@remix-run/react';

type ActivePromotionsProps = {
  promotions: PromotionCardProps[]
}

const ActivePromotions = ({promotions}: ActivePromotionsProps) => {
  return <Container width="full">
    <VStack gap={4}>
      <HStack width="full">
        <h1 className={h1()}>
          Active Promotions
        </h1>
      </HStack>
      <Grid columns={{base: 1, md:3}} width="full">
        {promotions.map(promotion => (
          <PromotionCard key={promotion.id} {...promotion}/>
        ))}
      </Grid>
      <Button asChild>
        <Link to="/promotions">
          See all Promotions
        </Link>
      </Button>
    </VStack>
  </Container>
}

export default ActivePromotions;
