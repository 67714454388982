import {ReactNode} from 'react';
import {Box} from '@styled-system/jsx';
import {h2, muted} from '@styled-system/recipes';

type HeroStatProps = {
  number: ReactNode
  label: ReactNode
}

const HeroStat = ({number, label}: HeroStatProps) => {
  return <Box textAlign="center">
    <div className={h2()}>
      {number}
    </div>
    <div className={muted()}>
      {label}
    </div>
  </Box>

}

export default HeroStat;
